import React from 'react';
import { isBrowser } from 'react-device-detect';
import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import Entities from './Entities';
import EntityDisplay from '../components/entityDisplay';

const BrowserEntity = Entities;

const MobileEntity = ({ pageContext }) => {
  const { entityName, data, selectedEntity } = pageContext;
  const entities = get(data, 'data.allMarkdownRemark.nodes', []);

  return (
    <Container maxWidth="lg" className="u-margin-t--lrg">
      <EntityDisplay
        entityData={selectedEntity}
        entityName={entityName}
        entities={entities}
      />
    </Container>
  );
};

MobileEntity.propTypes = {
  selectedEntity: PropTypes.object,
  pageContext: PropTypes.object.isRequired,
};

MobileEntity.defaultProps = {
  selectedEntity: undefined,
};

const Entity = isBrowser ? BrowserEntity : MobileEntity;

export default Entity;
